import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixCurrency',
  standalone: true
})
export class FixCurrencyPipe implements PipeTransform {

  /**
   *
   * @param value
   */
  transform(value: string | null): unknown {
    if (value && value.indexOf('BGN') !== -1) {
      value = value.replace('BGN', '') + 'лв';
    }

    return value;
  }
}
