import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {User} from "../models/User";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuth: boolean = false;
  private currentUser: User = {} as User;

  constructor(private http: HttpClient) { }

  get user(): User {
    return this.currentUser;
  }

  set user(user: User) {
    this.currentUser = user;
  }

  get isAuthenticated(): boolean {
    return this.isAuth;
  }

  set isAuthenticated(isAuth: boolean) {
    this.isAuth = isAuth;
  }

  signIn(data: {email: string, password: string}): Observable<any> {
    return this.http.post<any>(environment.backendUrl + '/users/signIn', data, {withCredentials: true});
  }

  signOut(): Observable<any> {
    return this.http.post<any>(environment.backendUrl + '/users/signOut', {}, {withCredentials: true});
  }

  signUp(data: any): Observable<any> {
    return this.http.post<any>(environment.backendUrl + '/users/signUp', data, {withCredentials: true});
  }

  verifyCookie(): Observable<any> {
    return this.http.get<Observable<any>>(environment.backendUrl + '/users/verifyCookie', {withCredentials: true});
  }

  updateUser(user: any): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/users', user, {withCredentials: true});
  }
}
